<template>
  <change-password-form></change-password-form>
</template>

<script>
import ChangePasswordForm from "@/components/ChangePasswordForm.vue";

export default {
  components: {
    ChangePasswordForm,
  },
  data() {
    return {};
  },
};
</script>
